import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import { makeStyles } from '@material-ui/core/styles';

import Navbar from 'components/Navbars/Navbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import styles from 'assets/jss/material-dashboard-react/layouts/adminStyle.js';

import bgImage from 'assets/img/menu.jpg';
import logo from 'assets/img/logonova.png';
import menuRoutes from '../../routes/menu.routes';

import EditAdminPage from '../../pages/Admin/edit';
import EditAlertPage from '../../pages/Alert/edit';
import EditGroupPage from '../../pages/Group/edit';
import EditUserPage from '../../pages/User/edit';
import EditRoutePage from '../../pages/Route/edit';
import AddRouteManualPage from '../../pages/Route/add_route_manual';
import AddRouteAutomaticPage from '../../pages/Route/add_route_automatic';


let ps;

const switchRoutes = (
  <Switch> 
   
    <Route path="/admin/administrator/:id" isPrivate component={EditAdminPage} /> 
    <Route path="/admin/alert/:id" isPrivate component={EditAlertPage} /> 
    <Route path="/admin/group/:id" isPrivate component={EditGroupPage} /> 
    <Route path="/admin/user/:id" isPrivate component={EditUserPage} /> 
    <Route path="/admin/route/manual" isPrivate exact component={AddRouteManualPage} /> 
    <Route path="/admin/route/automatic" isPrivate exact component={AddRouteAutomaticPage} /> 
  
    <Route path="/admin/route/:id" isPrivate component={EditRoutePage} /> 
  
    {menuRoutes.map((prop, key) => {     
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          /> 
        );    
    })}  
    
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const classes = useStyles();
  const mainPanel = React.createRef();

  const [image, setImage] = React.useState('');
  const [color, setColor] = React.useState('white');
  const [fixedClasses, setFixedClasses] = React.useState('dropdown show');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === 'dropdown') {
      setFixedClasses('dropdown show');
    } else {
      setFixedClasses('dropdown');
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => window.location.pathname !== '/admin/maps';
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={menuRoutes}
        logoText="Weloc"
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={menuRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />

        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}

      </div>
    </div>
  );
}
