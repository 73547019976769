import React, { useState, useEffect, useCallback } from 'react';
import 'date-fns';
import ptBRLocale from "date-fns/locale/pt-BR";

import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function User() {

  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataInicial, setDataInicial] = useState(new Date(new Date() - (365 * 86400000)));
  const [dataFinal, setDataFinal] = useState(new Date());
  const [data, setData] = useState([]);
  const { addToast } = useToasts()

  useEffect(() => {
      getData();
  }, []);

  const handleDataInicialChange = (date) => {
    setDataInicial(date);
  };

  const handleDataFinalChange = (date) => {
    setDataFinal(date);
  };

  const getData = useCallback(async () => {
    try {
      setLoading(true);
   
      const response = await api.post("/user/search", {
        filters: { order: 'mais_recentes', dataInicial, dataFinal }
      });

      const users = [];

      response.data.users.map(u => {
    
        users.push({
          id: u.id,
          name: u.name,   
          nickname: u.nickname,
          created_at: new Date(u.created_at).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }),
          type: u.type === 'A' ? 'ADMIN' : 'USUARIO'
        })
      });

      setTotal(response.data.total);
      setData(users);
      setLoading(false);

    }
    catch(e){
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });     
      setLoading(false);   
    } 
  },[dataInicial, dataFinal]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não',          
        }
      ]
    });

  },[dataInicial, dataFinal]);

  const postDeleteAction =  useCallback(async (id) => {
      try {
          await api.delete(`/user/${id}`);
          setData(data => data.filter(d => d.id != id));
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });        
      }
  },[]);

  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Usuários do aplicativo</h4>
            <p className={classes.cardCategoryWhite}>
              Lista de usuários do aplicativo.
            </p>
          </CardHeader>
          <CardBody>

                       
         <MuiPickersUtilsProvider utils={DateFnsUtils}  locale={ptBRLocale}>           
             <DatePicker style={{marginTop: 20}} label="Data cadastro inicial" value={dataInicial} onChange={handleDataInicialChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}  locale={ptBRLocale}>           
             <DatePicker style={{marginTop: 25}} label="Data cadastro final" value={dataFinal} onChange={handleDataFinalChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
          </MuiPickersUtilsProvider>

          <Button color="primary" type="button" style={{marginTop: 20}} onClick={() => {
         
            if (page>1){
              setPage(1);
            } else {
              getData();
            }
          }}>Filtrar</Button>

          {total > 50 && <Pagination page={page} onChange={(ev, page) => {setPage(page)}} count={total} size="large" style={{marginTop:30}}/>}    
              
          {data && data.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['Nome', 'Apelido', 'Tipo Usuario', 'Cadastrado em', '', '']}
              tableColumn={['name', 'nickname', 'type', 'created_at', 'edit', 'delete']}
              tableColumnSize={['30%', '20%', '20%', '20%', '1%', '1%']}
              tableData={data} 
              resource="user"            
              onDelete={(id) => handleDelete(id)}
           
           /> : isLoading ? (
            <Box mt={6}>
             <CircularProgress />
            </Box>               
          ) : (           
            <p style={{marginTop: 20}}>Nenhum usuario cadastrado no momento.</p>
          )}

        {total > 50 && <Pagination page={page} onChange={(ev, page) => {setPage(page)}} count={total} size="large" style={{marginTop:30}}/>}    
            
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
