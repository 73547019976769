import styled from 'styled-components';


export const Container = styled.div`
  margin-top: 25px;
  flex-direction: column;
  margin-bottom: 0px;
  width: 100%;
  border: ${props => (props.grey ? 0 : 1)}px;
  border-radius: 30px;
  background-color: ${props => (props.grey ? '#efefef' : '#fff')};
  height: auto;
  min-height: 60px;
  z-index: 1;
`;

export const LabelContainer = styled.div`
  background-color: #fff;
  width: auto;
  padding-right: 3px;
  padding-left: 3px;
  position: absolute;
  top: -10px;
  left: 20px;
  z-index: 1;
`;

export const Label = styled.label`
  color: #000;
  margin-bottom: 5px;
  font-size: 18px;
  z-index: 1;
  
`;

export const Error = styled.label`
  margin-bottom: 0px;
  margin-top: 10px;
  margin-left: 25px;
  color: #FF0000;
  font-size: 18px;
`;

export const InputIcon = styled.img`
  width: 16px;
  margin-top: 2px;
  margin-right: 0px;
  resize-mode: contain;
`;

export const InputContainer = styled.div`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 54px;
  z-index: 1;
  text-align: ${props => (props.textCenter ? 'center' : 'left')};
`;
