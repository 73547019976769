import {useState, useCallback} from 'react';
import api from '../../services/api';
import debounce from 'lodash.debounce';


const usePlacesAutocomplete = (initialValue, accessToken, countryId) => {
  const [value, setValue] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([]);

  const handleChange = async e => {
   
    const inputText = e.target.value;
    setValue(inputText);
    debouncedGet(inputText);

  };

  const debouncedGet = useCallback(
		debounce(inputText => getData(inputText), 1000),
		[], // será criada apenas uma vez inicialmente
	);

  const getData = async (inputText) => {
    const response = await api.post('/route/automaticRoute', {
      location: inputText
    });

    const results = await response.data;
    setSuggestions(results);
  }
  return {
    value,
    setValue,
    onChange: handleChange,
    suggestions,
    setSuggestions
  };
};

export default usePlacesAutocomplete;
