import 'date-fns';
import { Link } from 'react-router-dom';
import {useEffect, useCallback, useState, useRef} from 'react';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';
import Button from 'components/CustomButtons/Button.js';
import TextField from '@material-ui/core/TextField';

import {
  SelectedLocation,
  Location,
  Label,
} from './styles';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import LocationInputForm from 'components/LocationInputForm';
import { CircularProgress } from '@material-ui/core';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function AddRouteAutomatic() {

  const history = useHistory();
  const [name, setName] = useState('');
  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts()
  const { id } = useParams();   
  const classes = useStyles();

  const handleSave = async () => {
    try {
      setLoading(true);

      if (!origin || !destination){
        addToast('Informe a origem e destino !', {
          appearance: 'error',
          autoDismiss: true,
        });
        setLoading(false);
        return false;
      }
      await api.post('route', {
        origin,
        destination,
        name,
        type: 'automatic',
        user
      });

      setName('');
      setUser('');
      
      addToast('Cadastrado com sucesso !', {
        appearance: 'success',
        autoDismiss: true,
      });

      history.push("/admin/route");
    }
    catch(e){
      setLoading(false);


      addToast(e.response.data?.message
        ? e.response.data?.message
        : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });     
  
    }
  }

  const getUsers = useCallback(async () => {
    try {
  
      const response = await api.post("/user/search", {
        filters: {
          order: 'ordem_alfabetica',       
        }
      });
  
      const users = [];
  
      response.data.users.map(s => {
        users.push({
          id: s.id,
          name: s.name,   
          nickname: s.nickname      
        })
      })
  
      setUsers(users);
      setLoading(false);
    }
    catch(e){} 
  },[]);

  useEffect(() => {
    getUsers();
  },[]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Cadastrar Rota Automática</h4>
            </CardHeader>
            <CardBody>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
              <TextField
                 variant="outlined"
                 margin="normal"
                 required
                 fullWidth
                 id="name"
                 label="Nome da Rota"
                 name="name"
                 value={name}
                  onChange={(e) => setName(e.target.value)}                     
               />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{marginTop: 10, marginBottom: 10}}>
              <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 5}}>
                  <InputLabel>Usuario</InputLabel>             
                    <Select 
                          labelId="user"
                          label="Usuário"
                          variant="outlined"                  
                          name="user"
                          fullWidth
                          value={user}
                          onChange={(e) => setUser(e.target.value)}
                          >     
                          <MenuItem value="T">
                            TODOS
                            </MenuItem> 
                            {users && users.map(u => 
                            <MenuItem value={u.id}>
                            {u.name} - {u.nickname}
                            </MenuItem>)
                            }                      
                                                    
                    </Select>
                </FormControl>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <LocationInputForm
                  id="origin"
                  onPlaceSelect={data => {
                    setOrigin(data);
                  }}
                  name="origin"
                  label="Origem"
                  placeholder="Informe a origem"
                  onClearInput={() => {
                      setOrigin(null);
                  }}
                />
                {origin && (
                  <SelectedLocation>
                    <Label>Origem Selecionada: </Label>
                    <Location>{origin?.description}</Location>
                  </SelectedLocation>
                )}

              </GridItem>
       
              <GridItem xs={6} sm={6} md={6}>
                <LocationInputForm
                  id="destination"
                  onPlaceSelect={data => {
                    setDestination(data);
                  }}
                  name="destination"
                  label="Destino"
                  placeholder="Informe o destino"
                  onClearInput={() => {
                      setDestination(null);
                  }}
                />

                {destination && (
                  <SelectedLocation>
                    <Label>Destino Selecionado: </Label>
                    <Location>{destination?.description}</Location>
                  </SelectedLocation>
                )}
               </GridItem>
            </GridContainer>

            {loading ? <CircularProgress /> : <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{marginTop: 10}}>
                  <Button color="primary" onClick={() => handleSave()}>Salvar</Button>
                  <Link to="/admin/route"> <Button color="info">VOLTAR</Button></Link>
              </GridItem>
            </GridContainer>}
           

            </CardBody>
          
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
