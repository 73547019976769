
import {Container, Label, InputContainer, LabelContainer} from './styles';
import GooglePlacesAutocomplete from '../../components/GooglePlacesAutoComplete';

export default function LocationInputForm({
  error,
  icon,
  placeholder,
  multiline,
  grey,
  onPlaceSelect,
  onClearInput,
}) {
  return (
    <Container grey={grey}>
  
      <InputContainer hasIcon={icon} multiline={multiline}>
        <GooglePlacesAutocomplete
          onPlaceSelect={onPlaceSelect}
          onClearInput={onClearInput}
          placeholder={placeholder}

        />
      </InputContainer>
    </Container>
  );
}
