
import styled from 'styled-components';


export const Header = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 0px;
`;

export const Form = styled.div`
  padding: 0px 30px;
  align-itens: center;
  justify-content: center;
  margin-bottom: 10px;
  height: auto;
`;

export const FormInputs = styled.div`
  margin-top: 20px;
  width: 100%;
  height: auto;
`;

export const Title = styled.label`
  font-size: 18px;
  color: #000;
  font-weight: bold;
`;

export const SubTitle = styled.label`
  color: #000
  margin-bottom: 5px;
  font-size: 12px;

  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const SelectedLocation = styled.div`
  background-color: #feffef;
  width: auto;
  padding: 30px;
  align-itens: center;
  justify-content: center;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-top: 20px;
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
  display: block;
  color: #9bc44c;
`;

export const Location = styled.label`
  color: #000;
  font-size: 14px;
`;
