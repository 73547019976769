import 'date-fns';
import { Link } from 'react-router-dom';

import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditAlert() {
  const [isLoading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [action, setAction] = useState('create');
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
    else{
      setLoaded(true);
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);



  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();
     
      const title = e.target.title.value;
      const description = e.target.description.value;
      
      const schema = Yup.object().shape({
        title: Yup.string().required('Título é obrigatório !'),   
        description: Yup.string().required('Texto é obrigatório !')                   
      });         

      await schema.validate({title, description}, {
        abortEarly: false,
      });
    
      const postData = {  
        id,
        title,
        description
      };
  
      if (action === 'create'){
        await api.post(`/alert`, postData);
      }
      else{
        await api.put(`/alert/${id}`, postData);
      }
      history.push('/admin/alert');
   
    }
    catch(e){ 

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
          
  
    }
  },[action]);

  const loadData = useCallback(async () => {    
    try {
      setLoading(true);
      const response = await api.get(`/alert/${id}`);
      setTitle(response.data.title);  
      setDescription(response.data.description);

      setLoaded(true);
      setLoading(false);

    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/alert");
    }
  },[]);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Alerta</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>
           
              {loaded ?
              <> 
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="title"                      
                      label="Titulo"
                      name="title"                      
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      autoFocus
                  />
                </GridItem>               
              </GridContainer>          


              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      multiline={true}
                      rows={5}
                      fullWidth
                      id="description"                     
                      label="Texto"
                      name="description"                      
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    
                  />
                </GridItem>               
              </GridContainer>          
              
              </> : isLoading ? (
                <Box mt={6}>
                <CircularProgress />
                </Box>               
              ) : null}                 
            
                    
            </CardBody>
            {loaded ? <CardFooter>
              <Button color="primary" type="submit">Salvar</Button>
              <Link to="/admin/alert"> <Button color="info">VOLTAR</Button></Link>
            </CardFooter> : null}
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
