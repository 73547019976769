
import usePlacesAutocomplete from './usePlacesAutocomplete';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';

/**
 * Main Component
 */
const GooglePlacesAutocomplete = ({
  id,
  placeholder = '',
  accessToken = '',
  onPlaceSelect,
  onClearInput,
  name,
  label,

}) => {
  const placesAutocomplete = usePlacesAutocomplete('', accessToken, '');

  return (
    <div style={styles.container}>
      <TextField
        id={id}
        variant="outlined" 
        margin="normal"
        required
        fullWidth
        label={label}
        name={name}
        placeholder={placeholder}  
        {...placesAutocomplete}    
        style={{zIndex: 1}}         
       />
     
      {placesAutocomplete.suggestions?.length > 0 && (
          <PlaceSuggestionList {...{placesAutocomplete, onPlaceSelect}} />
        )}
    </div>
  );
};

/** Place Suggestion List below text input */
const PlaceSuggestionList = ({placesAutocomplete, onPlaceSelect}) => {
  return (
    <div style={styles.suggestionList}>
      {placesAutocomplete.suggestions.map((suggestion, index) => {
        return (
          <a href='#' 
            style={{display: 'block', padding: 10, backgroundColor: '#fff'}}
            key={index}
            onClick={() => {
              placesAutocomplete.setSuggestions([]);
              placesAutocomplete.setValue('');
              onPlaceSelect && onPlaceSelect(suggestion);
            }}>
            <span style={styles.suggestionItem}>{suggestion.description}</span>
          </a>
        );
      })}
    </div>
  );
};

export default GooglePlacesAutocomplete;

const styles = {
  container: {
    zIndex: 1,
    position: 'relative',
    height: 32,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 1,
    width: '100%',
  },
  input: {
    width: '80%',
    borderRadius: 4,
    fontSize: 16,
  },
  clearBtnImage: {width: 20, height: 20},
  clearBtn: {
    position: 'absolute',
    top: 6,
    right: 5,
  },
  suggestionList: {
    position: 'absolute',
    zIndex: 200,
    paddingHorizontal: 5,
    backgroundColor: '#000',
    borderRadius: 4,
    marginHorizontal: 2,
    top: 42,
    left: 2,

  },
  suggestionItem: {
    color: '#000',
    fontWeight: '300',
    fontSize: 20,
    marginBottom: 12,
    borderBottomWidth: 3,
    borderBottomColor: '#000',
    marginTop: 2,
    zIndex: 201,
  },
  creditBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 4,
  },
  creditText: {
    color: '#6b7280',
    fontWeight: '400',
    fontSize: 12,
    padding: 2,
  },
  creditImage: {width: 16, height: 16, marginLeft: 2},
};
