import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import 'assets/css/react-confirm-alert.css'; // Import css

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { confirmAlert } from 'react-confirm-alert';

import { Pagination } from '@mui/material';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Group() {

  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [name, setName] = useState('');
  const [total, setTotal] = useState(0);
  const {addToast} = useToasts();
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState('');

  const getUsers = useCallback(async () => {
    try {

      const response = await api.post("/user/search", {
        filters: {
          order: 'ordem_alfabetica',
         
        }
      });

      setTotal(response.data.total);

      const users = [];

      response.data.users.map(s => {
        users.push({
          id: s.id,
          name: s.name,   
          nickname: s.nickname      
        })
      })

      setUsers(users);
      setLoading(false);
    }
    catch(e){} 
  },[]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });

  },[]);

  const postDeleteAction =  useCallback(async (id) => {
      try {
          await api.delete(`/group/${id}`);
          setData(data => data.filter(d => d.id != id));
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });        
      }
  },[]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      
      const response = await api.post("/group/search", {
        filters: {
          owner_id: user,
          name
        },
        page
      });

      const groups = [];

      response.data.groups.map(g => {
        groups.push({
          id: g.id,
          name: g.name,   
          user: g.user.name,
          image: g.photo,
          total: g.members.length,
          created_at: new Date(g.created_at).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })
        })
      });

      setData(groups);
      setLoading(false);

    }
    catch(e){
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });     
      setLoading(false);   
    } 
  },[user, page, name]);

  useEffect(() => {
    getUsers();
  },[]);

   useEffect(() => {
      getData();
   }, [page]);
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Grupos</h4>
            <p className={classes.cardCategoryWhite}>
              Lista dos grupos cadatrados
            </p>
          </CardHeader>
          <CardBody>

          <TextField
             variant="outlined"
             margin="normal"
             fullWidth
             id="text"
             label="Nome do grupo"
             name="name"
             type="text"
             onChange={(e) => setName(e.target.value)}
                      
          />

          <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 5}}>
            <InputLabel>Usuario</InputLabel>             
               <Select 
                    labelId="user"
                    label="Usuário"
                    variant="outlined"                  
                    name="user"
                    fullWidth
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    >     
                     <MenuItem value="T">
                       TODOS
                      </MenuItem> 
                      {users && users.map(u => 
                      <MenuItem value={u.id}>
                       {u.name} - {u.nickname}
                      </MenuItem>)
                      }                      
                                              
              </Select>
           </FormControl>

           <Button color="primary" type="button" style={{marginTop: 20}} onClick={() => {
            if (page>1){
              setPage(1);
            } else {
              getData();
            }
          }}>Filtrar</Button>
                
          {total > 50 && <Pagination page={page} onChange={(ev, page) => {setPage(page)}} count={total} size="large" style={{marginTop:30}}/>}    
     
          {data && data.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['', 'Nome grupo', 'Usuário', 'Total de Membros', 'Criado em', '', '']}
              tableColumn={['image', 'name', 'user', 'total', 'created_at', 'view', 'delete']}
              tableColumnSize={['1%', '30%', '20%', '20%', '20%', '1%']}
              tableData={data} 
              resource="group"            
              onDelete={(id) => handleDelete(id)}
           
           /> : isLoading ? (
            <Box mt={6}>
             <CircularProgress />
            </Box>               
          ) : (           
            <p style={{marginTop: 20}}>Nenhum grupo cadastrado.</p>
          )}

          {total > 50 && <Pagination page={page} onChange={(ev, page) => {setPage(page)}} count={total} size="large" style={{marginTop:30}}/>}
                    
                    
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
